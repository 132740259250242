// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-it-works-js": () => import("../src/pages/howItWorks.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invest-js": () => import("../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-psp-info-js": () => import("../src/pages/pspInfo.js" /* webpackChunkName: "component---src-pages-psp-info-js" */),
  "component---src-pages-risk-js": () => import("../src/pages/risk.js" /* webpackChunkName: "component---src-pages-risk-js" */),
  "component---src-pages-security-js": () => import("../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */)
}

